import React from "react";

function Blog() {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <h2>Latest news from our PetIO</h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <div
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/erbil.jpg")' }}
              ></div>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 1, 2024</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    PetIO Officaily Lunched in Erbil Iraq Enjoy Exssulsive
                    Offers From us
                  </a>
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_2.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_3.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Blog;
