import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { loadAllScritps } from "../scritpsAll";
import { PublicRequest } from "../Requests/Requests";
import { io } from "socket.io-client";
import { useRef } from "react";
import Loader from "../Components/Loader";
import DownloadApp from "../Components/DownloadApp";
// import "./../css/style.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const ImageContainer = styled.div`
  background-color: red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PetImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
`;

const ContetnContainer = styled.div`
  width: 100%;
  background-color: white;
  bottom: 20px;
  position: relative;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;

  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const NameAndWishList = styled.div`
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PetName = styled.h3`
  margin: 0;
  line-height: 1;
`;

const Button = styled.button`
  background-color: pink;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 5px;
`;

const Detalies = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const DetaliesItem = styled.div`
  background-color: red;
  width: 100px;
  height: 80px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  flex-direction: column;
  font-size: 19px;
  &:nth-of-type(1) {
    background-color: #b35255;
  }
  &:nth-of-type(2) {
    background-color: #ffab75;
  }
  &:nth-of-type(3) {
    background-color: #a976b8;
  }
`;
const Tilte = styled.h5`
  margin: 0;
`;
const SubTitle = styled.h6`
  margin: 0;
`;
const OnwerDetales = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
`;
const Owner = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
`;
const OwnerImage = styled.img`
  width: 70px;
  object-fit: contain;
  border-radius: 50%;
`;
const OwnerName = styled.h5`
  margin: 0;
`;

const Contacts = styled.div`
  display: flex;
  gap: 10px;
`;

const Descritpion = styled.p`
  padding: 0 10px;
  text-align: left;
  color: gray;
`;

const ActionOverlay = styled.div`
  background-color: #0000004a;

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 300;
  display: ${(state) => (state.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const ActionFlow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FlowP = styled.p`
  text-align: center;

  font-weight: bold;
  color: white;
  padding: 0 20px;
`;

const ActionFlowButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const InputFields = styled.input`
  border: none;
  border-radius: 15px;
  padding: 5px;
  width: 80%;
  margin: 0 auto;
`;

function PetDetails() {
  //const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [isModalVisible, setModalVisible] = useState(true);
  const [info, setInfo] = useState();
  const [locationSeand, setLocationSend] = useState(false);
  const [founcerInfo, setFounderInfo] = useState({});
  const ioRef = useRef();
  const [coords, setCoords] = useState(null);
  const params = useParams();

  const [error, setError] = useState(null);
  //Make here daynamic Data For Flow in struct
  const [flowData, setFlowData] = useState({
    title: "Hello, I have lost my family.",
    content:
      "Please, can you accept the location request? I told my family I'm fine. :( ",
    stage: 1,
  });

  const [scanId, setScanId] = useState("");

  const pushNotfactionSend = useCallback(
    (data = {}) => {
      console.log("noti send");
      ioRef.current = io("https://petioapi.onrender.com");
      ioRef.current?.emit("newScan", { id: params?.id, data });
    },
    [ioRef, params?.id]
  );

  const [showInformationForm, setShowInformationForm] = useState(false);

  useEffect(() => {
    PublicRequest.get(`/auth/getuser/${params.id}`).then((res) => {
      setInfo(res.data);
    });
    console.log(info);
  }, [params.id]);

  useEffect(() => {
    getLocation();
  }, []);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const toggleLocationSend = () => {
    setLocationSend(!locationSeand);
  };

  const handelInfoChange = (f, v) => {
    setFounderInfo({ ...founcerInfo, [f]: v });
  };

  const toggleSecondFlow = () => {
    setFlowData({
      content:
        "Thanks for sending your location to my family! Now, if you want to help me more, you can send your contact information to my family. Click 'Next.'",
      title: "OOOOllllhaaa We Notified the Owner",
      stage: 2,
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords);
          PublicRequest.post("/newScan", {
            location: {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            },
            petOwnerId: params.id,
          }).then((res) => {
            setScanId(res.data);
            // setModalVisible(false);
            toggleSecondFlow();
            toggleLocationSend();
            pushNotfactionSend();
          });
          setCoords(position.coords);
          setError(null);
        },
        (error) => {
          setError(error.message);
          setCoords(null);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const generateGoogleMapsLink = (latitude, longitude) => {
    const baseGoogleMapsURL = "https://www.google.com/maps/";
    const queryParameters = `?q=${latitude},${longitude}`;

    return baseGoogleMapsURL + queryParameters;
  };

  const sendLocationWithInfo = async () => {
    if (founcerInfo.founderName && founcerInfo.founderNumber) {
      const payload = {
        location: { latitude: coords.latitude, longitude: coords.longitude },
        petOwnerId: params.id,
        ...founcerInfo,
      };
      try {
        await PublicRequest.post("/newScan", payload).then((res) => {
          setScanId(res.data);
          setModalVisible(false);
          toggleLocationSend();
          pushNotfactionSend({ ...founcerInfo });
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const ActionDaynamicButton = () => {
    function flowAction() {
      if (flowData.stage === 1) {
        getLocation();
      } else if (flowData.stage === 2) {
        setShowInformationForm(true);
      } else {
        sendLocationWithInfo();
      }
    }

    const buttonFlowContetn =
      flowData.stage === 1
        ? "Request and send location again"
        : flowData.stage === 2
        ? "Next"
        : "Send";
    return <Button onClick={flowAction}>{buttonFlowContetn}</Button>;
  };

  const petImageAndStage = () => {
    switch (true) {
      case flowData.stage === 1 && info?.petInfo?.pet === "dog":
        return "/images/lostSad.png";
      case flowData.stage === 2 && info?.petInfo?.pet === "dog":
        return "/images/thanksDog.png";
      case flowData.stage === 1 && info?.petInfo?.pet === "cat":
        return "/images/lostCat.png";
      case flowData.stage === 2 && info?.petInfo?.pet === "cat":
        return "/images/ThanksCat.png";
      default:
        return "no type";
    }
  };

  if (!info || !params.id)
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );

  if (!info?.petInfo) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
        }}
      >
        <img src={"/images/catmad.jpeg"} alt="" />
        <h3 className="text-center">
          Please Update pet inforamiton from petIO app
        </h3>
        <p>Download app 👇</p>
        <br />
        <DownloadApp />
      </div>
    );
  }

  return (
    <div>
      <ActionOverlay show={isModalVisible}>
        <ActionFlow>
          <img style={{ width: "100%" }} src={petImageAndStage()} alt="" />
          <h3
            style={{
              margin: 0,
              fontWeight: "bold",
              color: "white",
              padding: 0,
            }}
          >
            {flowData.title}
          </h3>
          <FlowP>{flowData.content}</FlowP>

          {showInformationForm && (
            <>
              <InputFields
                name="founderName"
                type="text"
                placeholder="Name"
                onChange={(e) =>
                  handelInfoChange(e.target.name, e.target.value)
                }
              />
              <br />
              <InputFields
                type="tel"
                name="founderNumber"
                placeholder="phone"
                onChange={(e) =>
                  handelInfoChange(e.target.name, e.target.value)
                }
              />
            </>
          )}

          <ActionFlowButtons
            style={{ width: "100%" }}
            className=" w-100 d-flex justify-content-around align-items-center "
            onClick={() => sendLocationWithInfo()}
          >
            <Button onClick={() => setModalVisible(!isModalVisible)}>
              Close
            </Button>
            <ActionDaynamicButton />
          </ActionFlowButtons>
        </ActionFlow>
      </ActionOverlay>
      <Container>
        <ImageContainer>
          <PetImage src={info.petInfo.image} alt="Pet" />
        </ImageContainer>
        <ContetnContainer>
          <NameAndWishList>
            <div className=" d-flex flex-column">
              <PetName>{info.petInfo.name}</PetName>
              <span style={{ marginLeft: 10 }}>{info.location}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <a
                href={generateGoogleMapsLink(
                  info?.petInfo.daynamicLocation?.latitude,
                  info?.petInfo.daynamicLocation?.longitude
                )}
                style={{ fontSize: 10 }}
              >
                GO TO PET OWNER Location
              </a>
              <Button onClick={() => setModalVisible(!isModalVisible)}>
                Send Location again
              </Button>
            </div>
          </NameAndWishList>
          <Detalies>
            <DetaliesItem>
              <Tilte>{info.petInfo.sex}</Tilte>
              <SubTitle>Sex</SubTitle>
            </DetaliesItem>
            <DetaliesItem>
              <Tilte>2 Years</Tilte>
              <SubTitle>Age</SubTitle>
            </DetaliesItem>
            <DetaliesItem>
              <Tilte>{info.petInfo.weight}</Tilte>
              <SubTitle>Weghit</SubTitle>
            </DetaliesItem>
          </Detalies>
          <OnwerDetales>
            <Owner>
              <OwnerImage src={info.profilePic} />
              <div>
                <OwnerName>{info.name}</OwnerName>
                <span>Owner</span>
              </div>
            </Owner>
            <Contacts>
              <a style={{ textDecoration: "none" }} href={`tel:${info.phone}`}>
                <i
                  style={{ fontSize: 20, color: "pink" }}
                  className="fi fi-br-phone-flip"
                ></i>
              </a>
              <a style={{ textDecoration: "none" }} href={`sms: ${info.phone}`}>
                <i
                  style={{ fontSize: 20, color: "#7BBAF2" }}
                  className="fi fi-rr-comment-dots"
                ></i>
              </a>
            </Contacts>
          </OnwerDetales>

          <Descritpion>{info.petInfo.bio}</Descritpion>
        </ContetnContainer>
      </Container>
    </div>
  );
}

export default PetDetails;
