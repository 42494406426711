import React from "react";

function News() {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/erbil.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">March 25, 2024</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    PetIO Officaily Lunched in Erbil Iraq Enjoy Exssulsive
                    Offers From us
                  </a>
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_2.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_3.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_4.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_5.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex ftco-animate">
            <div className="blog-entry align-self-stretch">
              <a
                href="blog-single.html"
                className="block-20 rounded"
                style={{ backgroundImage: 'url("images/image_6.jpg")' }}
              ></a>
              <div className="text p-4">
                <div className="meta mb-2">
                  <div>
                    <a href="#">April 07, 2020</a>
                  </div>
                  <div>
                    <a href="#">Admin</a>
                  </div>
                  <div>
                    <a href="#" className="meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </div>
                </div>
                <h3 className="heading">
                  <a href="#">
                    Even the all-powerful Pointing has no control about the
                    blind texts
                  </a>
                </h3>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="row mt-5">
          <div className="col text-center">
            <div className="block-27">
              <ul>
                <li>
                  <a href="#">&lt;</a>
                </li>
                <li className="active">
                  <span>1</span>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  <a href="#">4</a>
                </li>
                <li>
                  <a href="#">5</a>
                </li>
                <li>
                  <a href="#">&gt;</a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default News;
