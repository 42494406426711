import React from "react";

function TopNav() {
  return (
    <div style={{ marginBottom: 20 }} className="wrap">
      <div className="container">
        <div className="row  d-sm-flex justify-content-center align-items-center flex-nowrap">
          <div
            style={{ lineHeight: 0.7 }}
            className="col-md-6 col-sm-6 d-flex align-items-center"
          >
            <p className="mb-0 phone pl-md-2">
              <a href="#" className="mr-2">
                <span className="fa fa-phone mr-1" /> 0751 122 4912
              </a>
              <a href="#">
                <span className="fa fa-paper-plane mr-1" /> support@petio.me
              </a>
            </p>
          </div>
          <div className="col-md-6 col-sm-6 d-flex justify-content-md-end">
            <div className="social-media">
              <p className="mb-0 d-flex">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-facebook">
                    <i className="sr-only">Facebook</i>
                  </span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-twitter">
                    <i className="sr-only">Twitter</i>
                  </span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-instagram">
                    <i className="sr-only">Instagram</i>
                  </span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-dribbble">
                    <i className="sr-only">Dribbble</i>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
