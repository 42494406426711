import React from "react";

function FAQ() {
  return (
    <section className="ftco-section bg-light ftco-faqs">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-md-last">
            <div
              style={{ boxShadow: "none", height: "auto" }}
              className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
            >
              {/* <a
                href="https://vimeo.com/45830194"
                className="icon-video popup-vimeo d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-play" />
              </a> */}
              <img
                style={{ width: "100%" }}
                src="images/lunalFinal.png"
                alt=""
              />
            </div>
            <div className="d-flex mt-3">
              {/* <div
                className="img img-2 mr-md-2"
                style={{ backgroundImage: "url(images/Product.png)" }}
              />
              <div
                className="img img-2 ml-md-2"
                style={{ backgroundImage: "url(images/2.png)" }}
              /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="heading-section mb-5 mt-5 mt-lg-0">
              <h2 className="mb-3">Frequently Asks Questions</h2>
              <p>
                Easily find answers to common questions with our FAQ section.
              </p>
            </div>
            <div
              id="accordion"
              className="myaccordion w-100"
              aria-multiselectable="true"
            >
              <div className="card">
                <div className="card-header p-0" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      href="#collapseOne"
                      className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p className="mb-0">How to Get PetIO ? </p>
                      <i className="fa" aria-hidden="true" />
                    </button>
                  </h2>
                </div>
                <div
                  className="collapse show"
                  id="collapseOne"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div className="card-body py-3 px-0">
                    <ol>
                      <li>
                        Download The app from AppStroe or Google Play Store.
                      </li>
                      <li>Go to get New Account</li>
                      <li>Choose Nearest Veterinary</li>
                      <li>Choose Best Plan For You</li>
                      {/* <li>Separated they live in Bookmarksgrove right</li> */}
                    </ol>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header p-0" id="headingTwo" role="tab">
                  <h2 className="mb-0">
                    <button
                      href="#collapseTwo"
                      className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <p className="mb-0">How to manage your pet ?</p>
                      <i className="fa" aria-hidden="true" />
                    </button>
                  </h2>
                </div>
                <div
                  className="collapse"
                  id="collapseTwo"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="card-body py-3 px-0">
                    <ol>
                      <li>Go to top right corener where person icon </li>
                      <li>
                        Click then side menu will apper choose and edit your pet
                        Setting
                      </li>
                      <li>Fill or edit all the information about your pet</li>
                      <li>Save</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header p-0" id="headingThree" role="tab">
                  <h2 className="mb-0">
                    <button
                      href="#collapseThree"
                      className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <p className="mb-0">How to Update my pet Location ? </p>
                      <i className="fa" aria-hidden="true" />
                    </button>
                  </h2>
                </div>
                <div
                  className="collapse"
                  id="collapseThree"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="card-body py-3 px-0">
                    <ol>
                      <li>Go to top right corener where person icon </li>
                      <li>Choose Pet info from left side bar.</li>
                      <li>Click Update Pet location</li>
                      <li>Test the New location</li>
                      <li>Save and Done !</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header p-0" id="headingFour" role="tab">
                  <h2 className="mb-0">
                    <button
                      href="#collapseFour"
                      className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <p className="mb-0">
                        What i Do after Purchase PetIO Unquie ID ?
                      </p>
                      <i className="fa" aria-hidden="true" />
                    </button>
                  </h2>
                </div>
                <div
                  className="collapse"
                  id="collapseFour"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="card-body py-3 px-0">
                    <ol>
                      <li>Download the app if You Don't have it </li>
                      <li>Login with the account provide in the card</li>
                      <li>go to your profile fill your information</li>
                      <li>go Pet Info fill your Pet info</li>
                      <li className=" text-danger">
                        Most importnat part Update your pet Daynamic location
                      </li>
                      <li>Save and you ready to go!!</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
