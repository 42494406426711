import React from "react";

function StatsBanner() {
  return (
    <section className="ftco-counter" id="section-counter">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 text-center">
              <div className="text">
                <strong className="number" data-number={300}>
                  0
                </strong>
              </div>
              <div className="text">
                <span>Saved Pets</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 text-center">
              <div className="text">
                <strong className="number" data-number={13}>
                  0
                </strong>
              </div>
              <div className="text">
                <span>Professional Doctors</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 text-center">
              <div className="text">
                <strong className="number" data-number={340}>
                  0
                </strong>
              </div>
              <div className="text">
                <span>on Going Products</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 text-center">
              <div className="text">
                <strong className="number" data-number={50}>
                  0
                </strong>
              </div>
              <div className="text">
                <span>Pets Hosted</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsBanner;
