import React, { useState } from "react";
import { sendEmail, handelInformationChatne } from "../service";
function FreeConstlate() {
  const [userInformation, setUserInformation] = useState({});

  return (
    <section
      className="ftco-appointment ftco-section ftco-no-pt ftco-no-pb img"
      style={{ backgroundImage: "url(images/bg_3.jpg)" }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row d-md-flex justify-content-end">
          <div className="col-md-12 col-lg-6 half p-3 py-5 pl-lg-5 ftco-animate">
            <h2 className="mb-4">
              Free Consultation, Service Request or PETIO Unique ID Order to
              Your Home{" "}
            </h2>
            <form
              onSubmit={(e) => sendEmail(e, setUserInformation)}
              className="appointment"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-field">
                      <div className="select-wrap">
                        <div className="icon">
                          <span className="fa fa-chevron-down" />
                        </div>
                        <select
                          onChange={(e) =>
                            handelInformationChatne(
                              e.target.name,
                              e.target.value,
                              setUserInformation,
                              userInformation
                            )
                          }
                          name="serviceType"
                          id="serviceType"
                          value={
                            userInformation?.serviceType || "Select services"
                          }
                          className="form-control"
                        >
                          <option disabled selected value="">
                            Select services
                          </option>
                          <option value="petIO UnquieID">PET IO ID</option>
                          <option value="Cat Sitting">Cat Sitting</option>
                          {/* <option value="">Dog Walk</option> */}
                          <option value="Pet Spa">Pet Spa</option>
                          <option value="Pet Grooming">Pet Grooming</option>
                          <option value="Pet Daycare">Pet Daycare</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <input
                    value={userInformation?.name || ""}
                    onChange={(e) =>
                      handelInformationChatne(
                        e.target.name,
                        e.target.value,
                        setUserInformation,
                        userInformation
                      )
                    }
                    name="name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Your Name"
                  />
                  <input
                    value={userInformation?.email || ""}
                    onChange={(e) =>
                      handelInformationChatne(
                        e.target.name,
                        e.target.value,
                        setUserInformation,
                        userInformation
                      )
                    }
                    name="email"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Your Email"
                  />
                </div>

                <div className="col-md-6 ">
                  <div className="form-group">
                    <input
                      value={userInformation?.phone || ""}
                      onChange={(e) =>
                        handelInformationChatne(
                          e.target.name,
                          e.target.value,
                          setUserInformation,
                          userInformation
                        )
                      }
                      name="phone"
                      type="tel"
                      className="form-control"
                      placeholder="Your Phone"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      value={userInformation?.address || ""}
                      onChange={(e) =>
                        handelInformationChatne(
                          e.target.name,
                          e.target.value,
                          setUserInformation,
                          userInformation
                        )
                      }
                      name="address"
                      type="text"
                      className="form-control"
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-wrap">
                      <div className="icon">
                        <span className="fa fa-calendar" />
                      </div>
                      <input
                        onChange={(e) =>
                          handelInformationChatne(
                            e.target.name,
                            e.target.value,
                            setUserInformation,
                            userInformation
                          )
                        }
                        name="date"
                        type="text"
                        className="form-control appointment_date"
                        placeholder="Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-wrap">
                      <div className="icon">
                        <span className="fa fa-clock-o" />
                      </div>
                      <input
                        onChange={(e) =>
                          handelInformationChatne(
                            e.target.name,
                            e.target.value,
                            setUserInformation,
                            userInformation
                          )
                        }
                        name="time"
                        type="text"
                        className="form-control appointment_time"
                        placeholder="Time"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      onChange={(e) =>
                        handelInformationChatne(
                          e.target.name,
                          e.target.value,
                          setUserInformation,
                          userInformation
                        )
                      }
                      value={userInformation?.message || ""}
                      name="message"
                      id=""
                      cols={30}
                      rows={7}
                      className="form-control"
                      placeholder="Message , Detalies"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      defaultValue="Send message"
                      className="btn btn-primary py-3 px-4"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FreeConstlate;
