import React from "react";

function SimpleFeature() {
  return (
    <section className="ftco-section bg-light ftco-no-pt ftco-intro">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
            <div className="d-block services active text-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span class="fi fi-br-track fa-2x"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Pet Tracking</h3>
                <p>
                  PetIO simplifies pet tracking, ensuring your furry friends
                  stay safe. Effortlessly monitor their location, minimizing the
                  risk of loss. With PetIO, say goodbye to worries and hello to
                  stress-free pet ownership.
                </p>
                <a
                  href="#"
                  className="btn-custom d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                  <i className="sr-only">Read more</i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
            <div className="d-block services text-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span class="fi fi-rr-shopping-cart-add fa-2x"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Shop</h3>
                <p>
                  Discover the ultimate pet shop within PetIO! From nutritious
                  pet food to fun games and stylish accessories, we’ve got
                  everything your furry friend needs. Shop conveniently in-app
                  and enjoy a wide range of high-quality products tailored to
                  keep your pets happy and healthy. With PetIO, caring for your
                  pet is easier and more enjoyable than ever.
                </p>
                <a
                  href="#"
                  className="btn-custom d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                  <i className="sr-only">Read more</i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
            <div className="d-block services text-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span class="fi fi-rr-user-md fa-2x"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Meet best Veterinary</h3>
                <p>
                  Meet the best veterinarians through PetIO! Connect with
                  trusted veterinary professionals who are dedicated to ensuring
                  your pet’s health and well-being. Whether you need routine
                  check-ups or expert advice, our app makes it easy to find and
                  consult with top veterinarians in your area. With PetIO, your
                  pet’s health is always in good hands
                </p>
                <a
                  href="#"
                  className="btn-custom d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                  <i className="sr-only">Read more</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SimpleFeature;
