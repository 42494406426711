import React, { useState } from "react";
import styled from "styled-components";
import { sendEmail, handelInformationChatne } from "../service";

const Input = styled.input`
  border-radius: 10px;
  border: pink solid 0.5px;
  padding: 5px;
  margin: 0 5px;
  width: 150px;
`;

function Join() {
  const [userInformation, setUserInformation] = useState({});

  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center mb-5">
            <h2 className="heading-section">Become PetIO Trusted Partiner</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-4">Fill Your information</h3>
                    <form
                      id="contactForm"
                      name="contactForm"
                      className="contactForm"
                      onSubmit={(e) => sendEmail(e, setUserInformation)}
                    >
                      <h6 className=" pb-3">Bussiens Information</h6>
                      <div className="row mb-5">
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-map-marker" />
                            </div>
                            <div className="text">
                              <p>
                                <span>Address:</span>{" "}
                                <Input
                                  required
                                  value={userInformation?.baddress || ""}
                                  onChange={(e) =>
                                    handelInformationChatne(
                                      e.target.name,
                                      e.target.value,
                                      setUserInformation,
                                      userInformation
                                    )
                                  }
                                  type="text"
                                  id="baddress"
                                  name="baddress"
                                  placeholder="Address"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-phone" />
                            </div>
                            <div className="text">
                              <p>
                                <span>Phone:</span>{" "}
                                <Input
                                  onChange={(e) =>
                                    handelInformationChatne(
                                      e.target.name,
                                      e.target.value,
                                      setUserInformation,
                                      userInformation
                                    )
                                  }
                                  required
                                  value={userInformation?.bphone || ""}
                                  type="tel"
                                  id="bphone"
                                  name="bphone"
                                  placeholder="Phone number..."
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-paper-plane" />
                            </div>
                            <div className="text">
                              <p>
                                <span>Work Email:</span>{" "}
                                <Input
                                  onChange={(e) =>
                                    handelInformationChatne(
                                      e.target.name,
                                      e.target.value,
                                      setUserInformation,
                                      userInformation
                                    )
                                  }
                                  value={userInformation?.bemail || ""}
                                  type="bemail"
                                  name="bemail"
                                  placeholder="Email..."
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                  <div className="dbox w-100 text-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-globe" />
                    </div>
                    <div className="text">
                      <p>
                      <span>Email:</span>{" "}
                         <a href="#">yoursite.com</a>
                      </p>
                    </div>
                  </div>
                </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Personal Information</h6>
                          <div className="form-group">
                            <label className="label" htmlFor="name">
                              Full Name
                            </label>
                            <input
                              onChange={(e) =>
                                handelInformationChatne(
                                  e.target.name,
                                  e.target.value,
                                  setUserInformation,
                                  userInformation
                                )
                              }
                              required
                              value={userInformation?.name || ""}
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label" htmlFor="email">
                              Email Address
                            </label>
                            <input
                              onChange={(e) =>
                                handelInformationChatne(
                                  e.target.name,
                                  e.target.value,
                                  setUserInformation,
                                  userInformation
                                )
                              }
                              value={userInformation?.email || ""}
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Email"
                            />
                            <input
                              required
                              onChange={(e) =>
                                handelInformationChatne(
                                  e.target.name,
                                  e.target.value,
                                  setUserInformation,
                                  userInformation
                                )
                              }
                              value={userInformation?.phone || ""}
                              type="tel"
                              className="form-control"
                              name="phone"
                              id="phone"
                              placeholder="phone"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="subject">
                              Subject
                            </label>
                            <input
                              onChange={(e) =>
                                handelInformationChatne(
                                  e.target.name,
                                  e.target.value,
                                  setUserInformation,
                                  userInformation
                                )
                              }
                              value={userInformation?.subject || ""}
                              type="text"
                              className="form-control"
                              name="subject"
                              id="subject"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="#">
                              Any Other information or Notes
                            </label>
                            <textarea
                              onChange={(e) =>
                                handelInformationChatne(
                                  e.target.name,
                                  e.target.value,
                                  setUserInformation,
                                  userInformation
                                )
                              }
                              name="message"
                              value={userInformation?.message || ""}
                              className="form-control"
                              id="message"
                              cols={30}
                              rows={4}
                              placeholder="Type here .... "
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="submit"
                              value="Send Message"
                              className="btn btn-primary"
                            />
                            <div className="submitting" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="col-md-5 d-flex align-items-stretch">
                  <div
                    className="info-wrap w-100 p-5 img"
                    style={{ backgroundImage: "url(images/img.jpg)" }}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Join;
