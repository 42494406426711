const stylesheets = [
  // "css/animate.css",
  // "css/owl.carousel.min.css",
  // "css/owl.theme.default.min.css",
  // "css/magnific-popup.css",
  // "css/bootstrap-datepicker.css",
  // "css/jquery.timepicker.css",
  // "css/flaticon.css",
  // "css/style.css",
];

const scripts = [
  // "js/jquery.min.js",
  // "js/jquery-migrate-3.0.1.min.js",
  // "js/popper.min.js",
  // "js/bootstrap.min.js",
  // "js/jquery.easing.1.3.js",
  // "js/jquery.waypoints.min.js",
  // "js/jquery.stellar.min.js",
  // "js/jquery.animateNumber.min.js",
  // "js/bootstrap-datepicker.js",
  // "js/jquery.timepicker.min.js",
  // "js/owl.carousel.min.js",
  // "js/jquery.magnific-popup.min.js",
  // "js/scrollax.min.js",
  "js/main.js",
];

export function loadAllScritps() {
  stylesheets.forEach((href) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = href;
    document.head.appendChild(link);
  });

  scripts.forEach((src) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  });
}

export function unloadAllScritps() {
  stylesheets.forEach((href) => {
    const link = document.querySelector(`link[href="${href}"]`);
    if (link) {
      document.head.removeChild(link);
    }
  });

  scripts.forEach((src) => {
    const script = document.querySelector(`script[src="${src}"]`);
    if (script) {
      document.body.removeChild(script);
    }
  });
}
