import React from "react";

function SectionBg({ section }) {
  return (
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: 'url("imdages/bg_2.jpg")' }}
      data-stellar-background-ratio="0.5"
    >
      <div className="handHandler ">
        <img style={{ height: "30vh" }} src="images/bghand.png" />
      </div>
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div style={{ zIndex: 3 }} className="col-md-9 ftco-animate pb-5">
            <p className="breadcrumbs mb-2">
              <span className="mr-2">
                <a href="index.html">
                  Home <i className="ion-ios-arrow-forward" />
                </a>
              </span>

              <span>
                {section} <i className="ion-ios-arrow-forward" />
              </span>
            </p>
            <h1 className="mb-0 bread">
              {section === "veterinary"
                ? "Meet Our Veterinary Doctor"
                : section}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionBg;
