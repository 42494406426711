import emailjs from "@emailjs/browser";
import swal from "sweetalert";

export const sendEmail = (e, setUserInformation) => {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_evu4xsp",
      "template_8hjcmwn",
      e.target,
      "Np6CL2LKdfAL4M7Jy"
    )
    .then(
      (result) => {
        console.log(result.text);
        swal({
          title: "Thanks !",
          text: "we will contact you soon",
        });
      },
      (error) => {
        console.log(error.text);
      }
    );
  setUserInformation({});
  // setModalShow(true);
};

export const handelInformationChatne = (
  n,
  v,
  setUserInformation,
  userInformation
) => {
  setUserInformation({
    ...userInformation,
    [n]: v,
  });
};
