import React from "react";

function Gallery() {
  return (
    <section className="ftco-section">
      {/* <div className="container">
        <div className="row justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <h2>Pets Gallery</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-1.jpg)" }}
            >
              <a
                href="images/gallery-1.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Cat</span>
                  <h2>
                    <a href="work-single.html">Persian Cat</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-2.jpg)" }}
            >
              <a
                href="images/gallery-2.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Dog</span>
                  <h2>
                    <a href="work-single.html">Pomeranian</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-3.jpg)" }}
            >
              <a
                href="images/gallery-3.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Cat</span>
                  <h2>
                    <a href="work-single.html">Sphynx Cat</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-4.jpg)" }}
            >
              <a
                href="images/gallery-4.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Cat</span>
                  <h2>
                    <a href="work-single.html">British Shorthair</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-5.jpg)" }}
            >
              <a
                href="images/gallery-5.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Dog</span>
                  <h2>
                    <a href="work-single.html">Beagle</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div
              className="work mb-4 img d-flex align-items-end"
              style={{ backgroundImage: "url(images/gallery-6.jpg)" }}
            >
              <a
                href="images/gallery-6.jpg"
                className="icon image-popup d-flex justify-content-center align-items-center"
              >
                <span className="fa fa-expand" />
              </a>
              <div className="desc w-100 px-4">
                <div className="text w-100 mb-3">
                  <span>Dog</span>
                  <h2>
                    <a href="work-single.html">Pug</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      Comming Soon 😎
    </section>
  );
}

export default Gallery;
