import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SectionBg from "../Components/SectionBg";
import FooterAndLoader from "../Components/FooterAndLoader";
import Imprest from "../Components/Imprest";
import Nav from "../Components/Nav";
import PackgesOffers from "../Components/PackgesOffers";
import FreeConstlate from "../Components/FreeConstlate";
import Gallery from "../Components/Gallery";
import { loadAllScritps, unloadAllScritps } from "../scritpsAll";
import Join from "../Components/Join";
import TopNav from "../Components/TopNav";
import Veterines from "../Components/Veterines";
import News from "../Components/News";

function Sections() {
  const params = useParams();
  useEffect(() => {
    try {
      params.section && loadAllScritps();
    } catch {
      console.error("Failed to load scripts");
    }
    return () => {
      unloadAllScritps();
    };
  }, [params.section]);

  const DaynamicView = () => {
    switch (params.section) {
      case "veterinary":
        return <Veterines />;
      case "services":
        return <Imprest detalies={true} />;
      case "pricing":
        return (
          <>
            <PackgesOffers />
            <FreeConstlate />
          </>
        );
      case "joinus":
        return (
          <>
            <Join />
          </>
        );
      case "gallery":
        return <Gallery />;
      case "news":
        return <News />;

      default:
        break;
    }
  };

  useEffect(() => {}, [params.section]);

  return (
    <>
      <TopNav />
      <Nav />
      <SectionBg section={params.section} />
      <DaynamicView />
      <FooterAndLoader />
    </>
  );
}

export default Sections;
