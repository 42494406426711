import React from "react";

function PackgesOffers() {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <h2>Subscription Type</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 ftco-animate">
            <div className="block-7">
              <div
                className="img"
                // style={{ backgroundImage: "url(images/pricing-1.jpg)" }}
              />
              <div className="text-center p-4">
                <span className="excerpt d-block">Basic</span>
                <span className="price">
                  <sup>$</sup> <span className="number">17</span>{" "}
                  <sub>12/mos</sub>
                </span>
                <ul className="pricing-text mb-5">
                  <li>
                    <span className="fa fa-check mr-2" />1 Pet
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" /> Our Store
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" /> All notifactions
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    Free Supports right now only for six month of app lunch
                  </li>
                </ul>
                <a href="#" className="btn btn-primary d-block px-2 py-3">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div className="block-7">
              <div
                className="img"
                // style={{ backgroundImage: "url(images/pricing-2.jpg)" }}
              />
              <div className="text-center p-4">
                <span className="excerpt d-block">Multi</span>
                <span className="excerpt d-block">Coming Soon</span>
                <span className="price">
                  <sup>$</sup> <span className="number">45</span>{" "}
                  <sub>12/mos</sub>
                </span>
                <ul className="pricing-text mb-5">
                  <li>
                    <span className="fa fa-check mr-2" />3 Pets
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    Stores And Vip Points
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" /> All notifactions
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />3 Free Supports per
                    Month
                  </li>
                </ul>
                <a href="#" className="btn btn-primary d-block px-2 py-3">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate">
            <div className="block-7">
              <div
                className="img"
                // style={{ backgroundImage: "url(images/pricing-3.jpg)" }}
              />
              <div className="text-center p-4">
                <span className="excerpt d-block">Super Multi</span>
                <span className="excerpt d-block">Coming Soon</span>
                <span className="price">
                  <sup>$</sup> <span className="number">110</span>{" "}
                  <sub>12/mos</sub>
                </span>
                <ul className="pricing-text mb-5">
                  <li>
                    <span className="fa fa-check mr-2" />5 Pets
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    Stores, Vip Points and Exssulsive Offers
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" /> All notifactions
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    15 Free Supports
                  </li>
                </ul>
                <a href="#" className="btn btn-primary d-block px-2 py-3">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PackgesOffers;
