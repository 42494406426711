import React from "react";

function SimpleLanding() {
  return (
    <div
      className="hero-wrap js-fullheight"
      style={{ backgroundImage: 'url("images/blankBg.jpg")' }}
      data-stellar-background-ratio="0.5"
    >
      <div className="handHandler ">
        <img src="images/bghand.png" />
      </div>
      <div className="overlay" />
      <div className="container">
        <div
          className="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
          data-scrollax-parent="true"
        >
          <div
            className="col-md-11 ftco-animate text-center"
            style={{ zIndex: 3 }}
          >
            <h1 className="mb-4">
              Track Keep your Pet Safe and Highest Quality Care For Pets You'll
              Love{" "}
            </h1>
            <p>
              {/* <a href="#" className="btn btn-primary mr-md-4 py-3 px-4">
                Learn more <span className="ion-ios-arrow-forward" />
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleLanding;
