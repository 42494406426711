import React from "react";

function DownloadApp() {
  return (
    <div id="newsspec-19854-app" class="news-app-promo">
      <div class="news-app-promo-text">
        {/* <div class="news-app-promo-text__tagline">
          The best way to get news on the go
        </div> */}
        <br />
        <div class="news-app-promo-text__download">Download the petIO App.</div>
      </div>
      <div class="news-app-promo__section">
        <div class="news-app-promo-subsection">
          <img
            style={{ borderRadius: 20 }}
            class="news-app-promo__bbc-logo"
            src="/images/iconNew.png"
            width="106"
            height="106"
          />
        </div>
        <div class="news-app-promo-subsection">
          <a
            class="news-app-promo-subsection--link news-app-promo-subsection--appstore"
            href="https://apps.apple.com/us/app/petio/id6502765928"
            target="_parent"
          >
            <img
              class="news-app-promo__app-store"
              src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg"
              width="161"
              height="auto"
              border="0"
            />
          </a>
          <a
            class="news-app-promo-subsection--link news-app-promo-subsection--playstore"
            href="https://play.google.com/store/apps/details?id=com.samadZuhair.petIO&pcampaignid=web_share"
            target="_parent"
          >
            <img
              class="news-app-promo__play-store"
              src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg"
              width="161"
              height="auto"
              border="0"
            />
          </a>
        </div>
      </div>
      <div class="news-app-promo__section"></div>
    </div>
  );
}

export default DownloadApp;
