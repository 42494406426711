import React from "react";

function Imprest({ detalies }) {
  return (
    <>
      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-5 d-flex">
              <div
                className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
                style={{ backgroundImage: "url(images/catBg.png)" }}
              ></div>
            </div>
            <div className="col-md-7 pl-md-5 py-md-5">
              <div className="heading-section pt-md-5">
                <h2 className="mb-4">Why PetIO ?</h2>
              </div>
              <div className="row">
                <div className="col-md-6 services-2 w-100 d-flex">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-stethoscope" />
                  </div>
                  <div className="text pl-3">
                    <h4>Care Advices</h4>
                    <p>
                      Our veterinarians provide tailored care advice for your
                      pet's health and happiness. Trust our guidance on
                      nutrition, grooming, and more.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 services-2 w-100 d-flex">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-customer-service" />
                  </div>
                  <div className="text pl-3">
                    <h4>Customer Supports</h4>
                    <p>
                      Count on our app's reliable customer support for
                      assistance whenever you need it.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 services-2 w-100 d-flex">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span
                      style={{ lineHeight: 0 }}
                      class="fi fi-rr-shipping-fast fa-2x"
                    ></span>
                  </div>
                  <div className="text pl-3">
                    <h4>Fast Delevery</h4>
                    <p>
                      Fast delivery available for your convenience, guaranteed.
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-6 services-2 w-100 d-flex">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-emergency-call" />
                  </div>
                  <div className="text pl-3">
                    <h4>Emergency Services</h4>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries.
                    </p>
                  </div>
                </div> */}
                <div className="col-md-6 services-2 w-100 d-flex">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-veterinarian" />
                  </div>
                  <div className="text pl-3">
                    <h4>Veterinary Help</h4>
                    <p>
                      Our app also offers veterinary assistance to address any
                      pet-related concerns you may have.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {detalies && (
        <section className="ftco-section bg-light">
          <h3 className=" text-center mb-5 pb-5">Exslusive Service From us</h3>
          <div className="container">
            <div className="row mt-5 pt-4">
              <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
                <div className="d-block services text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-blind" />
                  </div>
                  <div className="media-body p-4">
                    <h3 className="heading">Dog Walking</h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right.
                    </p>
                    <a
                      href="#"
                      className="btn-custom d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                      <i className="sr-only">Read more</i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
                <div className="d-block services text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-dog-eating" />
                  </div>
                  <div className="media-body p-4">
                    <h3 className="heading">Pet Daycare</h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right.
                    </p>
                    <a
                      href="#"
                      className="btn-custom d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                      <i className="sr-only">Read more</i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-self-stretch px-4 ftco-animate">
                <div className="d-block services text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-grooming" />
                  </div>
                  <div className="media-body p-4">
                    <h3 className="heading">Pet Grooming</h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right.
                    </p>
                    <a
                      href="#"
                      className="btn-custom d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                      <i className="sr-only">Read more</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Imprest;
