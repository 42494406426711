import React from "react";

function Veterines() {
  return (
    <div className="ftco-section bg-light">
      <div className="container">
        {/* <div className="row">
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-1.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Lloyd Wilson</h3>
                <span className="position mb-2">Health Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-2.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Rachel Parker</h3>
                <span className="position mb-2">Life &amp; Business Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-3.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Ian Smith</h3>
                <span className="position mb-2">Executive Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-4.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Alicia Henderson</h3>
                <span className="position mb-2">Health Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-5.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Lloyd Wilson</h3>
                <span className="position mb-2">Executive Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-6.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Rachel Parker</h3>
                <span className="position mb-2">Health Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-7.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Ian Smith</h3>
                <span className="position mb-2">Health Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="staff">
              <div className="img-wrap d-flex align-items-stretch">
                <div
                  className="img align-self-stretch"
                  style={{ backgroundImage: "url(images/staff-8.jpg)" }}
                />
              </div>
              <div className="text pt-3 px-3 pb-4 text-center">
                <h3>Fred Henderson</h3>
                <span className="position mb-2">Executive Coach</span>
                <div className="faded">
                  <p>
                    I am an ambitious workaholic, but apart from that, pretty
                    simple person.
                  </p>
                  <ul className="ftco-social text-center">
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-google" />
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        No One Join US Until NOW 😔
      </div>
    </div>
  );
}

export default Veterines;
