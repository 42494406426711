import React, { useState } from "react";

import { useParams } from "react-router";
import { PublicRequest } from "../Requests/Requests";

const SendRest = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [changed, setChanged] = useState(false);
  const params = useParams();
  const restPassword = async () => {
    try {
      if (password1 === password2) {
        const res = await PublicRequest.post(`/auth/reset/${params.token}`, {
          newPassword: password1,
        });
        setPassword1("");
        setPassword2("");
        setChanged(true);
        if (res.status === 200) {
          // Use an appropriate component for web instead of Alert
          alert("Success Password Changed: " + res.data.message);
          console.log(res.data.message);
        }
      }
    } catch (error) {
      // Use an appropriate component for web instead of Alert
      alert("Failed to Reset Password: " + error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  if (changed) {
    return (
      <div className="container">
        <h1>Password Changed successfully</h1>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="content">
          <h1>Reset Password</h1>
          <p>Write Your Password</p>
          <input
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            placeholder="New Password"
            className="inputStyle"
          />
          <input
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            placeholder="Retype New Password"
            className="inputStyle"
          />
          <button
            onClick={() => restPassword()}
            disabled={!password1 || !password2}
            className="button"
          >
            RESET
          </button>
        </div>
      </div>
    );
  }
};

export default SendRest;
