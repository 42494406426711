import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Sections from "./Pages/Sections";
import Loader from "./Components/Loader";

import PetDetalies from "./Pages/PetDetalies";
import SendRest from "./Pages/SendRest";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" loader={<Loader />} element={<Home isHome={true} />} />
        <Route path="/about" loader={<Loader />} element={<Home />} />

        <Route loader={<Loader />} path=":section" element={<Sections />} />
        <Route
          loader={<Loader />}
          path="/petDetales/:id"
          element={<PetDetalies />}
        />
        <Route
          loader={<Loader />}
          path="/SendRest/:token"
          element={<SendRest />}
        />
      </Routes>
    </>
  );
}

export default App;
